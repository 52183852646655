import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function IconLicenseNote({ icon, url }) {
  return (
    <div>
      <a href={url} style={{ textDecoration: "none" }}>
        <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
      </a>{" "}
      von <a href="https://fontawesome.com">fontawesome.com</a> ist lizenziert
      nach <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>.
    </div>
  )
}
