import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function ProtectedAddress() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            legalnotice {
              name
              street
              houseNumber
              zipCode
              city
            }
          }
        }
      }
    `
  )
  const {
    name,
    street,
    houseNumber,
    zipCode,
    city,
  } = site.siteMetadata.legalnotice
  const reverse = str =>
    str
      .split("")
      .reverse()
      .join("")
  return (
    <p
      style={{
        unicodeBidi: "bidi-override",
        direction: "rtl",
        textAlign: "left",
      }}
    >
      {reverse(name)}
      <br />
      {`${reverse(houseNumber)} ${reverse(street)}`}
      <br />
      {`${reverse(city)} ${reverse(zipCode)}`}
      <br />
      dnalhcstueD
    </p>
  )
}
