import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function ProtectedContact() {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            legalnotice {
              email
              phoneNumber
            }
          }
        }
      }
    `
  )
  const { email, phoneNumber } = site.siteMetadata.legalnotice
  const reverse = str =>
    str
      .split("")
      .reverse()
      .join("")
  return (
    <p
      style={{
        unicodeBidi: "bidi-override",
        direction: "rtl",
        textAlign: "left",
      }}
    >
      {`${reverse(phoneNumber).replace(")0(", "(0)")} :nofeleT`}
      <br />
      <Link to="/contact">{`${reverse(email)
        .replace("@", "{ta}")
        .replace(".", "{tknup}")}`}</Link>{" "}
      :liamE
    </p>
  )
}
