import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import {
  faAt,
  faInfoCircle,
  faRssSquare,
} from "@fortawesome/free-solid-svg-icons"
import { faClock, faCalendarAlt } from "@fortawesome/free-regular-svg-icons"
import IconLicenseNote from "../components/icon-license-note"
import ProtectedAddress from "../components/protected-address"
import ProtectedContact from "../components/protected-contact"

const LegalNoticePage = ({ data }) => (
  <Layout>
    <SEO
      title="Impressum"
      description={data.site.siteMetadata.description}
    ></SEO>
    <h1>Impressum</h1>
    <h2>Angaben gemäß §5 TMG</h2>
    <ProtectedAddress></ProtectedAddress>
    <h3>Kontakt</h3>
    <ProtectedContact></ProtectedContact>
    <h2>Angaben gemäß §55 Abs. 2 RStV</h2>
    <p>
      Chefredakteur, Herausgeber und inhaltlich Verantwortlicher gemäß §55 Abs.
      2 RStV ist:
    </p>
    <ProtectedAddress></ProtectedAddress>
    <h2>Adressverarbeitung</h2>
    <p>
      Alle die auf dieser Webseite angegebenen Kontaktinformationen von
      Personen, inklusive etwaiger Fotos dienen ausdrücklich nur zu
      Informationszwecken bzw. zur Kontaktaufnahme. Sie dürfen insbesondere
      nicht für die Zusendung von Werbung, Spam und Ähnliches genutzt werden.
      Einer werblichen Nutzung dieser Daten wird deshalb hiermit widersprochen.
      Sollten diese Informationen dennoch zu den vorstehend genannten Zwecken
      genutzt werden, behalten wir uns etwaige rechtliche Schritte vor.
    </p>
    <h2>Haftungsausschluss</h2>
    <h3>Haftung für Inhalte</h3>
    <p>
      Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf
      diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis
      10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet,
      übermittelte oder gespeicherte fremde Informationen zu überwachen oder
      nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
      hinweisen.
    </p>
    <p>
      Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
      nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
      diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer
      konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
      Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
    </p>
    <h3>Haftung für Links</h3>
    <p>
      Unser Angebot enthält Links zu externen Websites Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar.
    </p>
    <p>
      Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch
      ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
      entfernen.
    </p>
    <h3>Urheberrecht</h3>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet.
    </p>
    <p>
      Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
      werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
      Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
      Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
      entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
      wir derartige Inhalte umgehend entfernen.
    </p>
    <h2>Datenschutz</h2>
    <p>
      Auf dieser Webseite werden keine personenbezogenen Daten erhoben,
      gespeichert oder verarbeitet. Es werden keine Cookies verwendet.
    </p>
    <h2>Lizenzangaben</h2>
    <ul>
      <li>
        GatsbyJS: <a href="/licenses/gatsbyjs.txt">MIT</a>
      </li>
      <li>
        React: <a href="/licenses/react.txt">MIT</a>
      </li>
      <li>
        react-scroll-up: <a href="/licenses/react-scroll-up.txt">MIT</a>
      </li>
      <li>
        react-helmet: <a href="/licenses/react-helmet.txt">MIT</a>
      </li>
      <li>
        PrismJS: <a href="/licenses/prismjs.txt">MIT</a>
      </li>
      <li>
        KaTeX: <a href="/licenses/katex.txt">MIT</a>
      </li>
      <li>
        Font Awesome Icons:
        <ul>
          <li>
            <IconLicenseNote
              icon={faGithub}
              url={"https://fontawesome.com/icons/github"}
            ></IconLicenseNote>
          </li>
          <li>
            <IconLicenseNote
              icon={faLinkedin}
              url={"https://fontawesome.com/icons/linkedin"}
            ></IconLicenseNote>
          </li>
          <li>
            <IconLicenseNote
              icon={faAt}
              url={"https://fontawesome.com/icons/at"}
            ></IconLicenseNote>
          </li>
          <li>
            <IconLicenseNote
              icon={faRssSquare}
              url={"https://fontawesome.com/icons/rss-square"}
            ></IconLicenseNote>
          </li>
          <li>
            <IconLicenseNote
              icon={faClock}
              url={"https://fontawesome.com/icons/clock"}
            ></IconLicenseNote>
          </li>
          <li>
            <IconLicenseNote
              icon={faCalendarAlt}
              url={"https://fontawesome.com/icons/calendar-alt?style=regular"}
            ></IconLicenseNote>
          </li>
          <li>
            <IconLicenseNote
              icon={faInfoCircle}
              url={"https://fontawesome.com/icons/info-circle?style=solid"}
            ></IconLicenseNote>
          </li>
        </ul>
      </li>
    </ul>
  </Layout>
)

export default LegalNoticePage

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
  }
`
